import React, {useEffect, useState} from "react";
import {Body, Panel} from "@cjdev-internal/visual-stack/lib/components/Panel";
import {DataTable} from "@cjdev-internal/visual-stack/lib/components/Table/DataTable";
import './HostsList.css'

import {
  TableContainer,
  Table,
  THead,
  TBody,
  TFoot,
  Tr,
  Th,
  Td,
} from '@cjdev-internal/visual-stack/lib/components/Table';
import LoadingAnimation from "@cjdev-internal/visual-stack/lib/components/LoadingAnimation";
import {downloadJson} from "../api/report-api";
import FilterSelect from "./FilterSelect";
import {useLocation} from "react-router";
import {Tab, TabLabelContent, TabLayout} from "@cjdev-internal/visual-stack/lib/components/TabLayout";
import {ErrorMessage} from "./ErrorMessage";

export const GRAFANA_COL = 'Grafana Url';
export const ASSET_OWNER_COL = '08_asset_owner';
export const CURRENT_VP = "marin.sarbulescu@cj.com"

export const HostsList = () => {
  const [rows, setRows] = useState([]);
  const [headers, setHeaders] = useState();
  const [grafanaLinks, setGrafanaLinks] = useState({});
  const [summary, setSummary] = useState()
  const [error, setError] = useState()
  const [alert, setAlert] = useState(false)
  const [squads, setSquads] = useState([]);
  const [assetOwners, setAssetOwners] = useState([])

  const query = useQuery();
  const simulateBackendError = query.has("backend-error");
  const simulateFrontendError = query.has("frontend-error");

  useEffect(() => {
    downloadJson(simulateBackendError).then((report) => {
      if (simulateFrontendError) {
        throw new Error('fake front end error!!')
      }
      addCjAssetOwnerCol(report);
      const renameCols = {
        "Host": "Host Name",
        "03_os": "Operating System",
        "06_location": "Location",
        "component": "Component",
        "squad": "Squad",
        "oncallGroup": "Oncall Group",
        "11_patch_installed": "Patch Installed",
        "Patch Required": "Patch Required",
        "Up To date": "Up To Date",
        'Asset Owner': "Asset Owner",
        'Grafana Url': "Monitor Performance"
      }
      const {tableHeaders, rows} = createDetailTable(report, renameCols);
      setHeaders(tableHeaders);
      setRows(rows);
      const squadsList = [...new Set(rows.map(r => r.row[4]))].map((squad) => squad === '' ? 'No Owner' : squad);
      setSquads(squadsList);
      setGrafanaLinks(mapGrafanaLinks(report))
      setAssetOwners([...new Set(report.map(r=>r["08_asset_owner"]))])
      const summary = createSummaryTables(report, 'squad', 'Up To date')
      setSummary(summary)
    }).catch(e => {
      console.log('error', e);
      setError(e)
      setAlert(true)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div data-testid={'disclaimer'} >Disclaimer: Data on this page is refreshed daily. Server patching statistics might take up to 24 hours to reflect here.</div>
      <Panel>
        <Body>
          {alert ? ErrorMessage(setAlert) : !error ?
            <HostTables summary={summary} headers={headers} rows={rows} grafanaLinks={grafanaLinks}
                        squads={squads} assetOwners={assetOwners}/> : null}
        </Body>
      </Panel>
    </>
  )
}

const HostTables = ({summary, headers, rows, grafanaLinks, squads, assetOwners}) => {

  const [filter, setFilter] = useState({});
  const [filterState, setFilterState] = useState({});

  useEffect(() => {
    const filterKey = filter.filterColumn;
    if (!filter.filterOption) {
      const {[filterKey]: _, ...remainingFilters} = filterState;
      setFilterState(remainingFilters);
    } else {
      const filterValue = filter.filterOption.value === 'No Owner' ? '' : filter.filterOption.value;
      setFilterState({...filterState, [filterKey]: filterValue});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const applyFilters = (rows) => {
    return rows.filter((r) => {
      return Object.entries(filterState).map(filter => {
        const headerIndex = headers && headers.findIndex(
          (header => header.label && header.label === filter[0])
        );
        return r.row[headerIndex] === filter[1];
      }).every(val => val === true);
    });
  };

  return (<>
    <TableContainer>
      {!summary ? (
        <div className={'vs-data-table-loading-animation-wrapper'}>
          <LoadingAnimation/>
        </div>
      ) : <SummaryTable summary={summary}/>}
    </TableContainer>
    <>
      {!headers || <div data-testid={'detail-filter-container'} className={'detail-filter-container'}>
        <FilterSelect
          filterColumn={'Squad'}
          options={squads}
          setFilter={setFilter}
        />
        <FilterSelect
          filterColumn={'Up To Date'}
          options={['Yes', 'No']}
          setFilter={setFilter}
        />
        <FilterSelect
          filterColumn={'Asset Owner'}
          options={assetOwners}
          setFilter={setFilter}
        />
      </div>}
      <DataTable
        columns={headers}
        data={applyFilters(rows)}
        isLoading={!headers}
        onClick={cell => {
          window.open(grafanaLinks[cell.rowIndex], '_blank')
        }}
        data-testid='detail-table'
        id={'detail-table'}
      />
    </>
  </>);
};

const SummaryTable = ({summary}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const selectSummaryTab = (event, tabIndex) => {
    setSelectedTabIndex(tabIndex)
  }
  const DivWithBorder = props => (
    <div style={{border: '1px solid #e1e1e1'}}>{props.children}</div>
  );
  return <DivWithBorder>
    <TabLayout tabLayoutId={'summary-tables'}
               selectTab={selectSummaryTab}
               selectedIndex={selectedTabIndex}

    >
      <Tab
        label={<TabLabelContent>CJ Owned</TabLabelContent>}
        content={<SummaryTab summary={summary?.cjOwned}/>}
        data-testid='summary-cj-owned-tab'
      />
      <Tab
        label={<TabLabelContent>All Hosts</TabLabelContent>}
        content={<SummaryTab summary={summary?.all}/>}
        data-testid='summary-all-tab'
      />

    </TabLayout>
  </DivWithBorder>;
}

const SummaryTab = ({summary}) => {
  return (<Table>
    <THead>
      <Tr>
        {summary?.headers?.map((h, i) => <Th key={i}>{h.label}</Th>)}
      </Tr>
    </THead>
    <TBody>
      {summary?.rows?.map((r, i) =>
        <Tr key={i}>{r.map((c, j) => <Td key={i + '|' + j}>{c}</Td>)}</Tr>
      )}
    </TBody>
    <TFoot>
      <Tr>
        {summary?.footer?.map((c, i) => <Th key={i}>{c}</Th>)}
      </Tr>
    </TFoot>
  </Table>)
}
export const addCjAssetOwnerCol = report => {
  report.forEach(r => r['Asset Owner'] = r[ASSET_OWNER_COL])
};

export const createDetailTable = (report, renameCols) => {
  const tableHeaders = Object.values(renameCols).map(h => {
    return h === renameCols[GRAFANA_COL] ? {clickable: true} : {label: h};
  })
  const rows = report.map(json => {
    const row = [];
    Object.keys(renameCols).forEach(k => {
      let cell = json[k] || '';
      let cellText = k === GRAFANA_COL ? 'Monitor Performance' : cell;
      row.push(cellText)
    })
    return {row};
  })
  return {tableHeaders, rows};
};

export const mapGrafanaLinks = (report) => {
  const rowToLink = {}
  report.forEach((json, i) => {
    rowToLink[i] = json[GRAFANA_COL];
  });
  return rowToLink;
}

export const createSummaryTables = (report, squadHeader, uptodateHeader) => {
  const all = createSummaryTab(report, squadHeader, uptodateHeader);
  const cjOwned = createCjOwnedSummaryTab(report, squadHeader, uptodateHeader);
  const summaryTable = {all, cjOwned};
  return summaryTable;
};

export const createCjOwnedSummaryTab = (report, squadHeader, uptodateHeader) => {
  const cjOwnedReport = report.filter(r => r['Asset Owner'] === CURRENT_VP);
  const summaryTable = createSummaryTab(cjOwnedReport, squadHeader, uptodateHeader);
  return summaryTable;
};

export const createSummaryTab = (report, squadHeader, uptodateHeader) => {
  const perSquad = {}
  const allSquads = {total: 0, uptodate: 0}
  report.forEach(r => {
    const squadName = r[squadHeader] || 'No Owner';
    (perSquad[squadName] = perSquad[squadName] || {total: 0, uptodate: 0}).total += 1;
    allSquads.total += 1;
    if (r[uptodateHeader] === 'Yes') {
      perSquad[squadName].uptodate += 1;
      allSquads.uptodate += 1;
    }
  })
  const percent = v => v.total === 0 ? 100 : Math.round(v.uptodate / v.total * 100) + '%';
  const summaryRows = Object.entries(perSquad).map(([k, v]) => {
    return [k, v.total, v.uptodate, percent(v)]
  })
  const summaryFooter = ['Total', allSquads.total, allSquads.uptodate, percent(allSquads)]
  const summaryTable = {
    headers: ['Squad', 'Total Hosts', 'Total Up to Date', 'Percentage Up to Date'].map((h) => {
      return {label: h}
    }),
    rows: summaryRows,
    footer: summaryFooter
  }
  return summaryTable;
};

const useQuery = () => {
  let search = useLocation().search;
  return new URLSearchParams(search);
};
