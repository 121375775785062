import React from "react";
import Select from "@cjdev-internal/visual-stack/lib/components/Select";

const FilterSelect = ({filterColumn, options, setFilter}) => {
    return (
        <div data-testid={'detail-filter'} className={'detail-filter'}>
            <div className={'detail-filter-label'}>{filterColumn}</div>
            <Select
                className={'detail-filter-select'}
                options={toSelectOptions(options)}
                isClearable={true}
                onChange={(option) => setFilter(
                    {
                        filterColumn: filterColumn,
                        filterOption: option
                    })
                }
            />
        </div>
    )
};

const toSelectOptions = (options) => {
    return options.map(option => {
        return {
            value: option,
            label: option
        }
    })
}

export default FilterSelect;
