import DeployListIcon from "mdi-react/ViewListIcon";
import AccountKeyIcon from "mdi-react/AccountKeyIcon";
import AdminIcon from "mdi-react/AccountCogIcon";
import React from "react";
import {HostsList} from "./components/HostsList";
import {getUser} from "./cjauth";
import {Admin} from "./components/Admin";
import {ADMINS} from "./App";
import HomeIcon from "mdi-react/HomeIcon";
import {Home} from "./components/Home";
import {CJAwsAccess} from "./components/CJAwsAccess";
import {ReleaseToggle} from "./components/ReleaseToggle";
import InformationIcon from "mdi-react/InformationIcon";
import {CJDelivery} from "./components/CJDelivery";

export const routes = (features) => {
  const routeMappings = {};
  const addRoute = (path, name, header, Component, icon, showInSideNav = true, requiresAuth = true) => {
    routeMappings[path] = {path, name, header, Component, icon, showInSideNav, requiresAuth};
  };
  addRoute("/", "Home", "Welcome to CJ Ops!", ({...props}) => <Home {...props} />, <HomeIcon/>, true, false);
  addRoute("/cj-aws-access", "CJ AWS Access", "CJ AWS Access", ({...props}) => <CJAwsAccess {...props}  />, <AccountKeyIcon/>);
  addRoute("/server-audit", "Server Auditing", "CJ On-Prem Server Auditing", ({...props}) => <HostsList {...props} />, <DeployListIcon/>);
  addRoute("/delivery", "Delivery", "", ({...props}) => <CJDelivery {...props} />, <AdminIcon/>);
  addRoute("/delivery-landing-page", "Delivery Landing Page", "", ({...props}) => <CJDelivery {...props} />, <AdminIcon/>, false, true);
  const user = getUser();
  if (user != null) {
    const email = user['emailAddress'];
    const username = email.substring(0, email.indexOf('@'));
    if (ADMINS.includes(username.toLowerCase())) {
      addRoute("/admin", "Admin", "Admin", ({...props}) => <Admin {...props} />, <AdminIcon/>);
      addRoute("/cj-feature-toggle", "Release Toggle", "", ({...props}) => <ReleaseToggle {...props} />, <InformationIcon />);
    }
  }
  return routeMappings;
};

