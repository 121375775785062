import axios from 'axios';
import * as cjauth from "../cjauth";

export const getData = async url => {
  const jsonCall = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${cjauth.getAuthToken()}`
    }
  })
  return jsonCall.data
};

export const postData = async (url, body) => {
  const authToken = cjauth.getAuthToken()
  const httpRequestConfig = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    }
  }
  const jsonCall = await axios.post(url, body, httpRequestConfig)
  const data = jsonCall.data
  return data

}