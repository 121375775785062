import ClientOAuth2 from "client-oauth2";
import * as Storage from "./storage";

let cjAuth;

const getClientAuth = (authConfig) => {
    if(cjAuth === undefined && authConfig !== undefined) {
        cjAuth = new ClientOAuth2(authConfig, undefined);
    }
    return cjAuth
}

const getAuthRedirectUri = (authConfig, redirectPath) => {
    const state = {nonce: (+new Date()).toString(36), redirectPath};
    return getClientAuth(authConfig).token.getUri({
        state: btoa(JSON.stringify(state))
    });
};

const handleOAuthCallback = async (authConfig, href) => {
    const token = await getClientAuth(authConfig).token.getToken(href);
    Storage.set(Storage.AUTHENTICATION_TOKEN, token.accessToken);

    const memberUser = await fetchMemberUser(authConfig, token.accessToken)
    delete memberUser.companies;
    memberUser.emailAddress = memberUser.emailAddress.toLowerCase().trim()
    Storage.set(Storage.MEMBER_USER, btoa(JSON.stringify(memberUser)));

    const {redirectPath} = JSON.parse(atob(token.data.state));
    return {token: token.accessToken, redirectPath};
};

const logout = () => {
    Storage.remove(Storage.AUTHENTICATION_TOKEN);
    Storage.remove(Storage.MEMBER_USER);
}

const isAuthenticated = async (authConfig) => {
    const token = Storage.get(Storage.AUTHENTICATION_TOKEN);

    if(token !== undefined && token !== null) {
        const tokenVerification = await fetchWithAuthBearerToken(authConfig.tokenCheckUri, token);
        return tokenVerification.ok;
    }

    return false;
};

const getUser = () => {
    let memberUserValue = Storage.get(Storage.MEMBER_USER);
    if(memberUserValue !== null){
        return JSON.parse(atob(memberUserValue))
    }
}

const getAuthToken = () => Storage.get(Storage.AUTHENTICATION_TOKEN);

const fetchWithAuthBearerToken = async (url, token) => {
    return fetch(url, {
        headers: {
            authorization: `Bearer ${token}`
        }
    });
}

const fetchMemberUser = async (authConfig, token) => {
    const user = await fetchWithAuthBearerToken(authConfig.tokenCheckUri, token)
    const authUserId = await user.json()

    const response = await fetchWithAuthBearerToken(`${authConfig.memberUserUri}/${authUserId.userId}`, token);
    return await response.json();
}

export {handleOAuthCallback, getAuthRedirectUri, isAuthenticated, getUser, getAuthToken, logout}
