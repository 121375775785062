import React, {useEffect} from 'react';
import {getAuthRedirectUri} from "../impl/oauth";

export const CJLogin = ({authConfig, redirectPath}) => {
    useEffect(() => {
        window.location.assign(getAuthRedirectUri(authConfig, redirectPath));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authConfig]);
    return <div/>;
};
