import React, {useState} from "react";
import {getUser, logout, useAuthentication} from "../cjauth";

import {LinkGroup, LogoutLink, UserIcon} from "@cjdev-internal/visual-stack/lib/components/SideNav";
import {authConfig} from "../utils/authConfig";

export const SideNavUserMenu = () => {
  const [menuExpanded, setMenuExpanded] = useState(false);
  const {hasCheckedAuthentication, authenticated} = useAuthentication(authConfig());

  const user = hasCheckedAuthentication && (authenticated && getUser());

  return hasCheckedAuthentication && (
    authenticated
      ? <LinkGroup className="vs-sidenav-user-menu"
                   toggleSideNav={() => setMenuExpanded(!menuExpanded)}
                   label={user && `${user.emailAddress}`}
                   icon={<UserIcon firstInitial={user ? user.firstName.charAt(0) : 'C'}
                                   lastInitial={user ? user.lastName.charAt(0) : "J"}/>}
                   expanded={menuExpanded}
                   onClick={() => setMenuExpanded(!menuExpanded)}>
        <LogoutLink label={"Log out"} onLogout={() => {
          logout()
          window.location.reload()
        }}/>
      </LinkGroup>
      : <LinkGroup className="vs-sidenav-user-menu"
                   toggleSideNav={() => setMenuExpanded(!menuExpanded)}
                   label={"Log in"}
                   icon={<UserIcon firstInitial={"C"} lastInitial={"J"}/>}
                   onClick={() => window.location.href = "/login"}>
      </LinkGroup>
  )
};
