import React from 'react';
import Card from '@cjdev-internal/visual-stack/lib/components/Card/';
import './Home.css';


export const Home = () =>
    <>
      <div className="home-page">
        <div className="home-page-card-wrapper">
          <Card href="cj-aws-access" className="home-page-card">
            <div className="home-page-card-title">
              <span>AWS Access</span>
              <div>Get temporary credentials to authenticate with CJ AWS accounts both in CLI and AWS console.</div>
            </div>
          </Card>
          <Card href="server-audit" className="home-page-card">
            <div className="home-page-card-title">
              <span>Server Auditing</span>
              <div>Monitor patch status of on-prem servers.
              </div>
            </div>
          </Card>
          <Card href="delivery" className="home-page-card">
            <div className="home-page-card-title">
              <span>Delivery</span>
              <div>Monitor your build and deployments</div>
            </div>
          </Card>
        </div>
      </div>
    </>;
