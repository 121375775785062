export const getConfig = () => {

  const configEnvironments = {

    dev: {
      featureToggleUrl: 'https://griy6f8et9.execute-api.us-west-1.amazonaws.com/prod/',
      reportApiUrl: 'https://yxjwj5omq0.execute-api.us-west-1.amazonaws.com/prod/',
      accessControlUrl: 'https://vn2iqpo9re.execute-api.us-east-1.amazonaws.com/prod/',
    },
    prod: {
      featureToggleUrl: 'https://q6ap0dt4uj.execute-api.us-west-1.amazonaws.com/prod/',
      reportApiUrl: 'https://i1lvy9bb1k.execute-api.us-west-1.amazonaws.com/prod/',
      accessControlUrl: 'https://4ybonx0pcf.execute-api.us-east-1.amazonaws.com/prod/',
    },
  };

  const hostname = window.location.hostname;

  if (hostname.includes("local")) {
    return {
      // reportApiUrl: 'http://localhost:8080/',
      reportApiUrl: configEnvironments.dev.reportApiUrl,
      featureToggleUrl: configEnvironments.dev.featureToggleUrl,
      accessControlUrl: configEnvironments.prod.accessControlUrl,
      useStubs: true
    }
  }

  if (hostname.includes("cjpowered.com")) {
    const config = configEnvironments['dev'];
    config.accessControlUrl = configEnvironments.prod.accessControlUrl
    return config
  }

  if (hostname.includes("cj.dev")) {
    return configEnvironments['prod'];
  }

  throw Error("No app configuration found for this host: " + hostname);
}

