import React, {useEffect} from 'react';
import {handleOAuthCallback} from "../impl/oauth";

export const OauthCallback = ({authConfig, url}) => {
    useEffect(() => {
        (async () => {
            const {redirectPath} = await handleOAuthCallback(authConfig, url)
            window.location.assign(redirectPath || "/");
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authConfig]);

    return <div/>;
};
