export const authConfig = () => {
    const localConfig = {
        "clientId": "1wctwq1w9knae3fjx67ch9jy83",
        "authorizationUri": "https://login.cj.com/auth",
        "tokenCheckUri": "https://login.cj.com/token/verify",
        "memberUserUri": "https://members.cj.com/affapi/oauth/user",
        "redirectUri": "http://localhost:3000/oauth-callback"
    }

    const labConfig = {
        "clientId": "14669t4c0rw0j7j8a6qrgwp8nb",
        "authorizationUri": "https://login.cj.com/auth",
        "tokenCheckUri": "https://login.cj.com/token/verify",
        "memberUserUri": "https://members.cj.com/affapi/oauth/user",
        "redirectUri": "https://operations-console.architects.cjpowered.com/oauth-callback"
    }

    const prodConfig = {
        "clientId": "ntec4jf69r0xtr92pft3vjfew",
        "authorizationUri": "https://login.cj.com/auth",
        "tokenCheckUri": "https://login.cj.com/token/verify",
        "memberUserUri": "https://members.cj.com/affapi/oauth/user",
        "redirectUri": "https://operations.cj.dev/oauth-callback"
    }

    const hostname = window.location.hostname;

    if (hostname.includes("local")) {
        return localConfig;
    }

    if (hostname.includes("cjpowered.com")) {
        return labConfig;
    }

    if (hostname.includes("cj.dev")) {
        return prodConfig;
    }

    throw Error("No oauth configuration found for this host: " + hostname);
}
