import axios from "axios";

import {getConfig} from "../config";

export const getFeatures = async () => {
    try {
        const result = await axios.get(getConfig().featureToggleUrl)
        const data = result.data
        const features = data?.Items.filter(item => item?.enabled?.BOOL).map(item => item?.featureId?.S)
        return JSON.stringify(features)
    }
    catch (e) {
        console.error("Could not get feature toggles", JSON.stringify(e))
        return JSON.stringify([])
    }
}
