import axios from 'axios';
import * as cjauth from "../cjauth";
import {getConfig} from "../config";
const config = getConfig();

export const downloadJson = async (simulateBackendError) => {
  const backendUrl = config.reportApiUrl;
  let reportUrl = `${backendUrl}report`;
  if(simulateBackendError){
    reportUrl = `${backendUrl}report-error`;
  }
  const jsonCall = await axios.get(reportUrl, {
    headers: {
      Authorization: `Bearer ${cjauth.getAuthToken()}`
    }
  })
  return jsonCall.data
};
