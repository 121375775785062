import {useLocation} from "react-router-dom";
import {CJLogin} from "../cjauth";
import React from "react";
import {authConfig} from "../utils/authConfig";

export const Login = () => {
  const location = useLocation()
  return <CJLogin
    authConfig={authConfig()}
    redirectPath={(location.state && (location.state.referrer || "/")) || "/"}
  />
}
