import {getData} from "./api-utils";
import {getConfig} from "../config";

const config = getConfig();

export const testAccounts = async () => {
  const backendUrl = config.accessControlUrl;
  let url = `${backendUrl}admin/test-accounts`;
  return await getData(url);
};

export const getRoleAssignments = async () => {
  const backendUrl = config.accessControlUrl;
  let url = `${backendUrl}admin/role-assignments`;
  return await getData(url);
};
