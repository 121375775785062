import React, {useState} from "react";
import {Button} from "@cjdev-internal/visual-stack/lib/components/Button";
import LoadingAnimation from "@cjdev-internal/visual-stack/lib/components/LoadingAnimation";
import {getRoleAssignments, testAccounts} from "../api/admin-api";
import {Table, TBody, Td, Th, THead, Tr} from "@cjdev-internal/visual-stack/lib/components/Table";
import './Admin.css'
import {ErrorMessage} from "./ErrorMessage";

export const Admin = () => {

  const [alert, setAlert] = useState(false)

  const AccountsStatus = () => {
    const [loadingTestAccounts, setLoadingTestAccounts] = useState(false);
    const [accountsStatus, setAccountsStatus] = useState()

    const getAccountsStatus = () => {
      setLoadingTestAccounts(true);
      testAccounts().then((testResults) => {
        setAccountsStatus(testResults);
      }).catch(err => {
        console.error(err);
        setAlert(true);
      }).finally(() => {
        setLoadingTestAccounts(false);
      })
    }
    return (
      <>
        <form>
          <Button type="primary" onClick={getAccountsStatus} className={'access-btn'} data-testid={'check-accounts'}>Check
            Accounts for AWS Access</Button>
        </form>
        <div className={'accounts-status'}>
          {loadingTestAccounts &&
          <LoadingAnimation loadingMessage={'Testing Accounts ...'}/>
          }
          {(!loadingTestAccounts && accountsStatus) &&
          <Table>
            <THead>
              <Tr>
                <Th>Account Id</Th>
                <Th>Problem</Th>
              </Tr>
            </THead>
            <TBody>
              {Object.entries(accountsStatus).map((r, i) =>
                <Tr key={i}>{r.map((c, j) => <Td key={i + '|' + j}>{c}</Td>)}</Tr>
              )}
            </TBody>
          </Table>
          }
        </div>
      </>)
  };

  const RoleAssignments = () => {
    const [loading, setLoading] = useState(false);
    const [roleAssignments, setRoleAssignments] = useState()

    const verifyRoleAssignments = () => {
      setLoading(true);
      getRoleAssignments().then((results) => {
        setRoleAssignments(results);
      }).catch(err => {
        console.error(err);
        setAlert(true);
      }).finally(() => {
        setLoading(false);
      })
    }
    return (
      <>
        <form>
          <Button type="primary" onClick={verifyRoleAssignments} className={'access-btn'}
                  data-testid={'role-assignments'}>Verify
            AWS Role Assignments</Button>
        </form>
        <div className={'role-assignments'}>
          {loading &&
          <LoadingAnimation loadingMessage={'Loading Role Assignments ...'}/>
          }
          {(!loading && roleAssignments) &&
          <Table>
            <THead>
              <Tr>
                <Th>Username</Th>
                <Th>Role</Th>
                <Th>ARC Ticket</Th>
              </Tr>
            </THead>
            <TBody>
              {
                roleAssignments.map(r => {
                  return r.roles.map(x =>
                    <Tr key={r.username + '|' + x.role}>
                      <Td>{r.username}</Td>
                      <Td>{x.role}</Td>
                      <Td><a target={'_blank'} rel="noreferrer" href={x.arc}>{x.arc}</a></Td>
                    </Tr>
                  )
                })
              }
            </TBody>
          </Table>
          }
        </div>
      </>)
  };

  return (
    <>
      {alert && ErrorMessage(setAlert)}
      <AccountsStatus/>
      <RoleAssignments/>
    </>
  )


};
