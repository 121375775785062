import React, {useState} from "react";
import {Link} from "react-router-dom";
import {SideNavUserMenu} from "./SideNavUserMenu";
import Layout from '@cjdev-internal/visual-stack/lib/layouts/ApplicationLayout';
import {
  Link as VSLink,
  LinkContentWrapper,
  SideNav,
} from '@cjdev-internal/visual-stack/lib/components/SideNav';

import MainIcon from 'mdi-react/CheckBoldIcon';
import {routes} from "../routes";
import {getUser} from "../cjauth";

export const AppLayoutView = ({children, features}) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggleSideNav = () => setCollapsed(!collapsed)
  const user = getUser();

  return <Layout
      sideNavState={!collapsed}
      sideNav={
        <SideNav
            onClick={toggleSideNav}
            matches={undefined}
            collapsed={collapsed}
            userMenu={<SideNavUserMenu/>}
            logo={<MainIcon className="logo-icon"/>}
            appName={`Operations`}>
          {Object
              .values(routes(features))
              .filter(r => r.showInSideNav === true)
              .filter(r => user || r.requiresAuth === false)
              .map(route =>
                  <VSLink key={route.path}>
                    <Link to={`${route.path}`}>
                      <LinkContentWrapper icon={route.icon} label={route.name.toUpperCase()}/>
                    </Link>
                  </VSLink>
              )
          }
        </SideNav>}>
    <div>
      {children}
    </div>
  </Layout>;
}
